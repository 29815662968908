html,
body {
  margin: 0;
  /*overflow: hidden;*/
  /*height: 100vh;*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  /*overflow: hidden;*/
  /*height: 100vh;*/
  width: 100vw;
}
a {
  text-decoration: none !important;
  color: #1c8f73;
}
a:hover {
  text-decoration: none !important;
}
div[class*='color-picker-cont']:nth-child(2) {
  /* width: 100% !important;
  display: flex;
  align-items: center; */
}
/*
option:disabled,:selected {
  color: "lightgrey";
}*/
@media only screen and (min-width: 767px) {
  /* width */
  /* ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  } */

  /* Track */
  /* ::-webkit-scrollbar-track {
    background: rgb(230, 230, 230);
    border-radius: 20px;
  } */

  /* Handle */
  /* ::-webkit-scrollbar-thumb {
    background: rgb(190, 190, 190); 
    border-radius: 20px;
    transition: 1s; 
  } */

  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #888; 
  } */
}

.animate-enter {
  opacity: 0.01;
}

.animate-enter.animate-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.animate-leave {
  opacity: 1;
}

.animate-leave.animate-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
